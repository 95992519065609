@use "../../../styles/global";

.education .details .subtitle {
  font-size: 16px;
}

.education .header {
  display: flex;
}

.education .text-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.education .school h1 {
  font-size: global.$card-level1-heading;
  font-weight: 700;
}

.education .details .subtitle {
  font-size: global.$card-level2-heading;
  font-weight: 600;
  margin-right: 5px;
}

.education .details .years {
  font-size: 12px;
  margin-top: 5px;
}

.educationCard {
  flex-direction: inherit;
}
