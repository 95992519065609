@use "../../../styles/global";

.card {
  border-radius: 12px;
  padding: 15px;
  margin: 5px 5px;
  width: 30%;
  line-height: 1.35;
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
  transition: transform 0.2s ease-in-out;
}

.expandable:hover {
  cursor: pointer;
  transform: scale(0.98);  /* shrink on hover */
}

@media only screen and (max-width: global.$small-device) {
  .card {
    width: 100%;
  }
}
