.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  padding: 3px 0;
  border-radius: 6px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  transform: translate3d(-30%, -140%, 0px);
}
