@use "../../../styles/global";

.project-contents {
  padding: 25px 50px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.project-contents {
  color: global.$day-mode-main-text;
}

.night-mode .project-contents {
  color: global.$dark-mode-main-text;
}

.project-contents .top {
  display: flex;
  justify-content: space-between;
}

.project-contents .text {
  margin-top: 15px;
  font-size: 14px;
  line-height: global.$long-text-line-height;
}

.projectName {
  font-size: 18px;
  font-weight: 700;
  color: global.$project-color-accent;
}

.night-mode .projectName {
  color: global.$project-color-main;
}

.projectTags {
  margin-top: 5px;
}

.additional-details {
  margin-top: 10px;

  p {
    margin-top: 10px;
    line-height: 1.3;
  }

  b {
    font-weight: 800;
  }
}

.mainHeading {
  font-size: 17px;
  font-weight: bold;
}

@media only screen and (max-width: global.$small-device) {
  .project-contents {
    padding: 25px 30px;
  }
}
