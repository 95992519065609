@use "../../styles/global";

.header .information {
  display: flex;
}

.header .container {
  display: flex;
  justify-content: space-between;
}

.header .information .text {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header .text .name {
  font-size: 28px;
}

.header .links {
  display: flex;
}

.header .linkedin,
.header .resume,
.header .github {
  display: flex;
  align-items: center;
}

.header .linkedin,
.header .github {
  margin-left: 5px;
}

.profile img {
  height: 53px;
  width: auto;
  user-select: none;
  background: none;
}

.profile {
  position: relative;
}

.profile .profile-image {
  height: 53px;
  &:hover {
    animation: giggle 0.5s forwards 1;

  }
}

// show the text bubble on hover on desktop
@media screen and (min-width: global.$small-device) {
  .profile:hover .text-bubble {
    display: block;
    animation: fade 2.3s forwards;
  }
}

.profile .text-bubble {
  position: absolute;
  top: 35px;
  right: 65px;
  display: none;
  height: 20px;
  rotate: 20deg;
  transition: transform 0.2s ease-in-out;
  transform: translateY(-50px) translateX(3px);
  margin-left: 5px;
  &:hover {
    transform: translateY(-50px) translateX(5px) scale(1.1);
  }
}

.text .name {
  font-size: 23px;
  font-weight: 700;
  padding-left: 10px;
  
}

.day-mode .text .name {
  color: rgb(84, 84, 84);
}

.night-mode .text .name {
  color: #d4d4d4;
}

// animations for the profile image

//giggle side-to-side
@keyframes giggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// fade in and out
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// typewriter effect for header text
@media screen and (min-width: 465px) {
  .typed-out{
    overflow: hidden;
    border-right: .15em solid #0b84ff;
    white-space: nowrap;
    animation: typing 1s steps(20, end) forwards, blinking .8s 3, hide-cursor 0s ease-in 2.4s forwards;
    font-size: 1.6rem;
    width: 0;
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  @keyframes blinking {
    from { border-color: transparent }
    to { border-color: #0b84ff; }
  }
  @keyframes hide-cursor {
    from { border-right: .15em solid #0b84ff }
    to { border-right: none; }
  }
}