@use "../../styles/global";

.organization-section .title {
  color: global.$organization-color-accent;
}

.night-mode .organization-section .title {
  color: global.$organization-color-main;
}

.organization .role h1 {
  font-size: global.$card-level2-heading;
  font-weight: 700;
}

.organization .details .name {
  font-size: global.$card-level1-heading;
  font-weight: 600;
}

.organization .dates {
  font-size: 12px;
}