@use "../../../styles/global";
@use "../ExperienceGlobal.scss";

.experience .details .company {
  font-size: 16px;
}

.experience .header {
  display: flex;
}

.experience .description .text {
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}
