@use "../../../styles/global";

.award-contents {
  padding: 25px 50px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.award-contents {
  color: global.$day-mode-main-text;
}

.night-mode .award-contents {
  color: global.$dark-mode-main-text;
}

.award-contents .top {
  display: flex;
  justify-content: space-between;
}

.award-contents .text {
  font-size: 15px;
  font-weight: 600;
  line-height: global.$long-text-line-height;
}

.awardName {
  font-size: 18px;
  font-weight: 700;
  color: global.$award-color-accent;
}

.awardView .header-image {
  height: 200px;
  background-color: #ffffc7;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 40' width='110' height='55' transform='rotate(20)'%3E%3Cpath fill='%238c8c6d' fill-opacity='0.45' d='M17 15.245v6.872a.5.5 0 0 1-.757.429L12 20l-4.243 2.546a.5.5 0 0 1-.757-.43v-6.87a8 8 0 1 1 10 0zm-8 1.173v3.05l3-1.8 3 1.8v-3.05A7.978 7.978 0 0 1 12 17a7.978 7.978 0 0 1-3-.582zM12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'%3E%3C/path%3E%3C/svg%3E");
}

.night-mode .awardName {
  color: global.$award-color-main;
}

.awardView .description {
  margin-top: 15px;
  font-size: 14px;
  line-height: 1.25;
}

.awardView ul {
  list-style-type: "- ";
  margin-left: 13px;
}

.awardView ul li {
  margin-top: 7px;
}

@media only screen and (max-width: global.$small-device) {
  .award-contents {
    padding: 25px 30px;
  }
}
