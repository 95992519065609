@use "../../../../styles/global";

$hide-button-text-if-enabled-at: 425px;

.buttonComponent {
  display: inline-flex;
  padding: 4px 10px;
  border-radius: 8px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: white;
  border: 1px solid global.$card-border;
  color: black;
  user-select: none;
  transition: transform 0.2s ease-in-out;
}

.buttonComponent:hover {
  cursor: pointer;
  transform: scale(0.96);
}

.buttonComponent .icon {
  padding-top: 2px;
}

.buttonComponent .button-text {
  padding-left: 3px;
}

@media only screen and (max-width: $hide-button-text-if-enabled-at) {
  .hideWhenSmall {
    display: none;
  }
}
