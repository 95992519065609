.imageView {
  display: block;
  max-width: 100vw;
  max-height: 100vh;
  margin: auto;
}

.blackBackground {
  background: black;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
}
