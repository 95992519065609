@use "../../styles/global";

.experience-section {
  margin-top: 40px;
}

.experience-section .cards {
  display: flex;
  flex-wrap: wrap;
}

.experience-section .title {
  font-size: 25px;
  color: global.$experience-color-accent;
  font-weight: 800;
  margin-bottom: 10px;
  margin-left: 5px;
}

.night-mode .experience-section .title {
  color: global.$experience-color-main;
}