@use "../../../../styles/global";

.closeButton button {
  z-index: 2;
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 0;
  opacity: 0.95;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: global.$icon-color;
  box-shadow: global.$button-dropshadow;
  transition: transform 0.2s ease-in-out;
}

.closeButton button svg {
  width: 20px;
  height: 20px;
}

.closeButton button:hover {
  background-color: global.$button-hover-color;
  transform: scale(0.93);
}