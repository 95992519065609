@use "../../../../styles/global";

.expand button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

.expandShadow button {
  box-shadow: global.$button-dropshadow;
}

.expand button svg {
  width: 50%;
  height: 50%;
}

.expand button:hover {
  transform: scale(1.08);
}

@media only screen and (max-width: global.$small-device) {
  .expand button {
    display: flex;
  }
}
