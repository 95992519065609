// colors
$experience-color-main: rgb(199, 219, 255);
$experience-color-accent: rgb(109, 120, 140);

$organization-color-main: rgb(255, 209, 199);
$organization-color-accent: rgb(140, 114, 109);

$project-color-main: rgb(199, 255, 208);
$project-color-accent: rgb(109, 140, 114);

$education-color-main: rgb(255, 199, 229);
$education-color-accent: rgb(140, 109, 125);

$award-color-main: rgb(255, 255, 199);
$award-color-accent: rgb(140, 140, 109);

$card-header-accent: rgb(109, 120, 140);

$card-border: #707070;

$icon-color: black;
$button-hover-color: #efefee;

// note: html background color is set in data/darkmode.js
$day-mode-background: #f5f5f5;

$day-mode-main-text: #000000;
$dark-mode-main-text: #ffffff;

$dark-mode-text: #d4d4d4;
$dark-mode-background: #191919;

// breakpoints
$small-device: 1240px;
$carousel-breakpoint-small: 505px;

// font sizes
$card-level1-heading: 18px;
$card-level2-heading: 15px;

// misc
$long-text-line-height: 1.25;
$button-dropshadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;