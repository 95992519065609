@use "../../../styles/global";

.tagComponent {
  font-size: 10px;
  line-height: 1.5;
  font-weight: 700;
  padding: 1px 4px;
  margin-right: 3px;
  border-radius: 4px;
  display: inline-block;
  user-select: none;
}
