@use "../../../styles/global";
@use "../ExperienceGlobal.scss";

.experience-modal {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  position: relative;
  line-height: 1.3;
}

.experience-contents {
  color: global.$day-mode-main-text;
}

.night-mode .experience-contents {
  color: global.$dark-mode-main-text;
}

.header-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.details .logo img {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  background-color: white;
  border-radius: 10px;
  border: 3px solid white;
}

.details .left-justified {
  display: flex;
}

.location {
  margin-left: 4px;
}

.experience-contents .position h1 {
  color: global.$experience-color-accent;
  margin-top: 5px;
  margin-bottom: 10px;
}

.night-mode .experience-contents .position h1 {
  color: global.$experience-color-main;
}

.experience-contents .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.experience-contents .right-justified {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.details .dates-type {
  text-align: right;
  font-size: 12px;
}

.no-wrap {
  white-space: nowrap;
}

.main-details {
  line-height: 1.3;
}

.main-details .logo {
  margin-bottom: 5px;
}

.right-justified .action a {
  text-decoration: none;
}

.right-justified .action {
  display: flex;
  justify-content: flex-end;
}

.right-justified .action button {
  margin: 0px 0px 0px 6px;
}

.additional-details {
  margin-top: 10px;
}

.summary ul {
  list-style-type: "- ";
  margin-left: 13px;
}

.summary ul li {
  margin-bottom: 7px;
}

.summary {
  margin-bottom: 15px;
}

.body h4,
.body h3,
.body h2,
.body h1 {
  font-weight: 700;
  margin-bottom: 3px;
}

.body {
  line-height: global.$long-text-line-height;
  font-size: 14px;
}

.presentations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.presentations iframe {
  width: 100%;
  height: 250px;
  border-radius: 5px;
}

.body .additional-details p {
  margin-bottom: 16px;
}

.body .mainHeading {
  font-size: 15px;
}

.body .documentTitle {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}
