@use "../../../styles/global";

.organization-modal {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  position: relative;
  line-height: 1.3;
}

.organization-contents {
  color: global.$day-mode-main-text;
}

.night-mode .organization-contents {
  color: global.$dark-mode-main-text;
}

.organization-modal .body {
  padding-top: 10px;
}

.header-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.organization-modal .details .logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid white;
  background-color: white;
}

.organization .details .logo,
.organization .details .main-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.organization .details .logo {
  margin-bottom: 8px;
}

.details .left-justified {
  display: inline-block;
}

.organization-contents {
  padding: 15px 50px;
}

.organization-contents .name h1 {
  color: global.$organization-color-accent;
}

.night-mode .organization-contents .name h1 {
  color: global.$organization-color-main;
}

.organization-contents .details {
  display: flex;
  justify-content: space-between;
}

.organization-contents .right-justified {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.organization .main-details {
  line-height: 1.3;
}

.organization-modal .details .name {
  font-size: 23px;
}

.organization-modal .body .yearsActive,
.organization-modal .body .leadershipRoles {
  margin-bottom: 15px;
}

.organization-modal .body .yearsActive h4,
.organization-modal .body .leadershipRoles h4 {
  text-transform: uppercase;
  color: #919191;
  font-size: 13px;
  font-weight: 800;
}

.right-justified .action a {
  text-decoration: none;
}

.right-justified .action {
  display: flex;
  justify-content: flex-end;
}

.additional-details {
  margin-top: 10px;
}

.summary ul {
  list-style-type: "- ";
  margin-left: 13px;
}

.summary ul li {
  margin-bottom: 7px;
}

.body h4,
.body h3,
.body h2,
.body h1 {
  font-weight: 700;
  margin-bottom: 3px;
}

.body {
  line-height: global.$long-text-line-height;
  font-size: 14px;
}

.body .presentations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.body .presentations iframe {
  width: 100%;
  height: 250px;
  border-radius: 5px;
}

.body .additional-details p {
  margin-bottom: 16px;
}

.body .documentTitle {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}